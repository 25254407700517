import { AppVersionVo } from './app-version-vo';
import { DateVo } from './date-vo';

export class TemplateTagModel {
  constructor(
    private id: number,
    private name: string,
    private priority: number,
    private randomizable: boolean,
    private createdAt: DateVo,
    private updatedAt: DateVo,
    private isNiche: boolean,
    private needFeatureCompatibilityCheck: boolean,
    private minimalRequiredVersionIos: AppVersionVo | null,
    private minimalRequiredVersionAndroid: AppVersionVo | null,
  ) {}

  static create(
    id: number,
    name: string,
    priority: number,
    randomizable: boolean,
    createdAt: DateVo,
    updatedAt: DateVo,
    isNiche: boolean,
    needFeatureCompatibilityCheck: boolean,
    minimalRequiredVersionIos: AppVersionVo | null,
    minimalRequiredVersionAndroid: AppVersionVo | null,
  ): TemplateTagModel {
    return new TemplateTagModel(
      id,
      name,
      priority,
      randomizable,
      createdAt,
      updatedAt,
      isNiche,
      needFeatureCompatibilityCheck,
      minimalRequiredVersionIos,
      minimalRequiredVersionAndroid,
    );
  }

  static createMock() {
    return new TemplateTagModel(
      0,
      '',
      0,
      false,
      DateVo.create(new Date()),
      DateVo.create(new Date()),
      false,
      false,
      null,
      null,
    );
  }

  public getId() {
    return this.id;
  }

  public getName() {
    return this.name;
  }

  public getPriority() {
    return this.priority;
  }

  public getRandomizable() {
    return this.randomizable;
  }

  public getCreatedAt() {
    return this.createdAt;
  }

  public getUpdatedAt() {
    return this.updatedAt;
  }

  public getIsNiche() {
    return this.isNiche;
  }

  public getNeedFeatureCompatibilityCheck() {
    return this.needFeatureCompatibilityCheck;
  }

  public getMinimalRequiredVersionIos() {
    return this.minimalRequiredVersionIos;
  }

  public getMinimalRequiredVersionAndroid() {
    return this.minimalRequiredVersionAndroid;
  }

  public updateName(name: string) {
    this.name = name;
  }

  public updatePriority(priority: number) {
    this.priority = priority;
  }

  public updateRandomizable(randomizable: boolean) {
    this.randomizable = randomizable;
  }

  public updateIsNiche(isNiche: boolean) {
    this.isNiche = isNiche;
  }

  public updateNeedFeatureCompatibilityCheck(needFeatureCompatibilityCheck: boolean) {
    this.needFeatureCompatibilityCheck = needFeatureCompatibilityCheck;
  }

  public updateMinimalRequiredVersionIos(minimalRequiredVersionIos: AppVersionVo | null) {
    this.minimalRequiredVersionIos = minimalRequiredVersionIos;
  }

  public updateMinimalRequiredVersionAndroid(minimalRequiredVersionAndroid: AppVersionVo | null) {
    this.minimalRequiredVersionAndroid = minimalRequiredVersionAndroid;
  }

  public clone(): TemplateTagModel {
    return new TemplateTagModel(
      this.id,
      this.name,
      this.priority,
      this.randomizable,
      this.createdAt,
      this.updatedAt,
      this.isNiche,
      this.needFeatureCompatibilityCheck,
      this.minimalRequiredVersionIos,
      this.minimalRequiredVersionAndroid,
    );
  }
}
