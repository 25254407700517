import { TemplateCategoryStartFeedCardChipModel } from "./template-category-start-feed-card-chip-model";

export class PersistedTemplateCategoryStartFeedCardChipModel extends TemplateCategoryStartFeedCardChipModel {
  constructor(
    protected id: number,
    name: string,
    templateTagId: number | null,
  ) {
    super(name, templateTagId);
  }

  static create(id: number, name: string, templateTagId: number): PersistedTemplateCategoryStartFeedCardChipModel {
    return new PersistedTemplateCategoryStartFeedCardChipModel(id, name, templateTagId);
  }

  public clone(): PersistedTemplateCategoryStartFeedCardChipModel {
    return new PersistedTemplateCategoryStartFeedCardChipModel(this.id, this.name, this.templateTagId);
  }

  public isComplete(isNameRequired: boolean) {
    if (!this.templateTagId) return false;
    return isNameRequired ? !!this.name : true;
  }

  public getId(): number {
    return this.id;
  }
}
