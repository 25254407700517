import * as React from 'react';
import { LinkStartFeedCardModel } from '../models/link-start-feed-cards/link-start-feed-card-model';
import { PicField, PicImage, PicImageUpload, PicInput } from '@cardinalblue/pic-collage-cms-ui';
import { ListDataPagePanel } from '../../common/components/panels/list-data-page-panel';


type Props<T> = {
  linkStartFeedCard: T;
  onLinkStartFeedCardUpdate: (updatedStartFeedCard: T) => void;
  linkStartFeedCardCloner: (linkStartFeedCard: T) => T;
};

export function EditLinkStartFeedCardPanel<T extends LinkStartFeedCardModel>({ linkStartFeedCard, onLinkStartFeedCardUpdate, linkStartFeedCardCloner }: Props<T>) {
  const cloneLinkStartFeedCardToUpdate = React.useCallback((
    cb: (clonedLinkStartFeedCard: T) => T,
  ) => {
    onLinkStartFeedCardUpdate(cb(linkStartFeedCardCloner(linkStartFeedCard)));
  }, [linkStartFeedCard]);

  const imageUrl = React.useMemo(() => linkStartFeedCard.getImageUrl(), [linkStartFeedCard]);

  return (
    <ListDataPagePanel title='Link'>
      <PicField label='Link (Required)' description='Must start with "https://" or  "app:/"'>
        <PicInput
          value={linkStartFeedCard.getLink()}
          onInput={val => {
            cloneLinkStartFeedCardToUpdate(clonedLinkStartFeedCard => {
              clonedLinkStartFeedCard.updateLink(val);
              return clonedLinkStartFeedCard;
            });
          }}
        />
      </PicField>
      <PicField label='Image (Required)' description='Suggested dimensions: 670x670 pixels for "square", 670x420 pixels for "landscape", 670x840 pixels for "portrait".'>
        <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          {imageUrl && (
            <PicImage
              src={imageUrl}
              width='200px'
            />
          )}
          <PicImageUpload
            accept=''
            onUpload={uplodaedImage => {
              cloneLinkStartFeedCardToUpdate(clonedLinkStartFeedCard => {
                clonedLinkStartFeedCard.updateImage(uplodaedImage);
                return clonedLinkStartFeedCard;
              });
            }}
          />
        </div>
      </PicField>
    </ListDataPagePanel>
  );
};
