import * as React from 'react';
import { PicAppVersionInput, PicColor, PicCountriesInput, PicDatePicker, PicDropdown, PicField, PicIconButton, PicIconName, PicInput, PicLink, PicText} from '@cardinalblue/pic-collage-cms-ui';
import { StartFeedCardModel } from '../models/start-feed-card-model';
import { DateVo } from '../../common/models/date-vo';
import { CountryModel } from '../../common/models/country-model';
import { ListDataPagePanel } from '../../common/components/panels/list-data-page-panel';
import { isPersistedStartFeedCardModel } from '../models/utils';
import { AppVersionVo } from '../../common/models/app-version-vo';

type Props<T> = {
  countries: CountryModel[],
  startFeedCard: T;
  onStartFeedCardUpdate: (updatedStartFeedCard: T) => void;
  startFeedCardCloner: (startFeedCard: T) => T;
};

export function EditStartFeedCardPanel<T extends StartFeedCardModel>({ countries, startFeedCard, onStartFeedCardUpdate, startFeedCardCloner }: Props<T>) {
  const cloneStartFeedCardToUpdate = React.useCallback((
    cb: (clonedStartFeedCard: T) => T,
  ) => {
    onStartFeedCardUpdate(cb(startFeedCardCloner(startFeedCard)));
  }, [startFeedCard]);

  const startAt = React.useMemo(() => startFeedCard.getStartAt(), [startFeedCard]);
  const endAt = React.useMemo(() => startFeedCard.getEndAt(), [startFeedCard]);

  const targetFreeVipUsersItemGroups: {
    title: null,
    items: { label: string, value: 'all' | 'vip_only' | 'free_only' | 'none' }[],
  }[] = [
    {
      title: null,
      items: [
        { label: 'All', value: 'all' },
        { label: 'Vip Only', value: 'vip_only' },
        { label: 'Free Only', value: 'free_only' },
      ],
    },
  ];
  const targetFreeVipUsersValue = React.useMemo(() => {
    const isTargetFreeUser = startFeedCard.getIsTargetFreeUser();
    const isTargetVipUser = startFeedCard.getIsTargetVipUser();

    if (isTargetFreeUser && isTargetVipUser) return 'all'
    else if (!isTargetFreeUser && isTargetVipUser) return 'vip_only'
    else if (isTargetFreeUser && !isTargetVipUser) return 'free_only';
    return 'none';
  }, [startFeedCard]);

  const targetNewOldUsersItemGroups: {
    title: null,
    items: { label: string, value: 'all' | 'new_only' | 'old_only' | 'none' }[],
  }[] = [
    {
      title: null,
      items: [
        { label: 'All', value: 'all' },
        { label: 'New Only (Within 14 Days)', value: 'new_only' },
        { label: 'Old Only (Over 14 Days)', value: 'old_only' },
      ],
    },
  ];
  const targetNewOldUsersValue = React.useMemo(() => {
    const isTargetNewUser = startFeedCard.getIsTargetNewUser();
    const isTargetOldUser = startFeedCard.getIsTargetOldUser();

    if (isTargetNewUser && isTargetOldUser) return 'all'
    else if (!isTargetNewUser && isTargetOldUser) return 'old_only'
    else if (isTargetNewUser && !isTargetOldUser) return 'new_only';
    else return 'none';
  }, [startFeedCard]);

  const isCountriesExclusive = React.useMemo(() => startFeedCard.getIsCountriesExclusive(), [startFeedCard]);

  return (
    <ListDataPagePanel title='Basic'>
      {isPersistedStartFeedCardModel(startFeedCard) && (
        <PicField label='Id'>
          <PicText>{startFeedCard.getId()}</PicText>
        </PicField>
      )}
      <PicField label='Analytic Id (Required)'>
        {isPersistedStartFeedCardModel(startFeedCard) ? (
          <PicText>{startFeedCard.getAnalyticId()}</PicText>
        ) : (
          <PicInput
            value={startFeedCard.getAnalyticId()}
            onInput={val => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                clonedStartFeedCard.updateAnalyticId(val);
                return clonedStartFeedCard;
              });
            }}
          />
        )}
      </PicField>
      <PicField label='Title (Required)'>
        <PicInput
          value={startFeedCard.getTitle()}
          onInput={val => {
            cloneStartFeedCardToUpdate(clonedStartFeedCard => {
              clonedStartFeedCard.updateTitle(val);
              return clonedStartFeedCard;
            });
          }}
        />
      </PicField>
      <PicField label='Subtitle'>
        <PicInput
          value={startFeedCard.getSubtitle()}
          onInput={val => {
            cloneStartFeedCardToUpdate(clonedStartFeedCard => {
              clonedStartFeedCard.updateSubtitle(val);
              return clonedStartFeedCard;
            });
          }}
        />
      </PicField>
      <PicField label='Label'>
        <PicInput
          value={startFeedCard.getLabel()}
          onInput={val => {
            cloneStartFeedCardToUpdate(clonedStartFeedCard => {
              clonedStartFeedCard.updateLabel(val);
              return clonedStartFeedCard;
            });
          }}
        />
      </PicField>
      <PicField label='Start Date' description={`This start feed card starts precisely at 00:00:00 on ${startAt ? startAt.toDateString(-8) : 'this date'} in GMT-8 timezone`}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <PicDatePicker
            value={startAt?.toDateString(-8) ?? null}
            onPick={val => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                if (val === null) {
                  clonedStartFeedCard.updateStartAt(val);
                } else {
                  clonedStartFeedCard.updateStartAt(DateVo.parseDateString(val, -8));
                }
                return clonedStartFeedCard;
              });
            }}
          />
          <PicIconButton
            iconName={PicIconName.Close}
            onClick={() => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                clonedStartFeedCard.updateStartAt(null);
                return clonedStartFeedCard;
              });
            }}
          />
        </div>
      </PicField>
      <PicField label='End Date' description={`This start feed card ends precisely at 23:59:59 on ${endAt ? endAt.toDateString(-8) : 'this date'} in GMT-8 timezone`}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <PicDatePicker
            value={endAt?.toDateString(-8) ?? null}
            onPick={val => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                if (val === null) {
                  clonedStartFeedCard.updateEndAt(val);
                } else {
                  clonedStartFeedCard.updateEndAt(DateVo.parseDateString(val, -8).toEndOfDay(-8));
                }
                return clonedStartFeedCard;
              });
            }}
          />
          <PicIconButton
            iconName={PicIconName.Close}
            onClick={() => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                clonedStartFeedCard.updateEndAt(null);
                return clonedStartFeedCard;
              });
            }}
          />
        </div>
      </PicField>
      <PicField label='Target Countries'>
        <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          <div style={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
            <PicLink
              copy='Global'
              onClick={() => {
                cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                  clonedStartFeedCard.updateIsCountriesExclusive(true);
                  clonedStartFeedCard.updateCountries(['JP', 'MX', 'AR', 'CO', 'CL', 'PE', 'EC', 'ES']);
                  return clonedStartFeedCard;
                });
              }}
            />
            <PicLink
              copy='LATAM'
              onClick={() => {
                cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                  clonedStartFeedCard.updateIsCountriesExclusive(false);
                  clonedStartFeedCard.updateCountries(['MX', 'AR', 'CO', 'CL', 'PE', 'EC']);
                  return clonedStartFeedCard;
                });
              }}
            />
          </div>
          <div style={{ width: '140px' }}>
            <PicDropdown
              itemGroups={[
                {
                  title: null,
                  items: [
                    {
                      label: 'Include',
                      value: 'include',
                    },
                    {
                      label: 'Exclude',
                      value: 'exclude',
                    },
                  ],
                },
              ]}
              itemLabelGetter={item => item.label}
              itemValueGetter={item => item.value}
              value={isCountriesExclusive ? 'exclude' : 'include'}
              onSelect={item => {
                cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                  clonedStartFeedCard.updateIsCountriesExclusive(item.value === 'exclude');
                  return clonedStartFeedCard;
                });
              }}
            />
          </div>
          <PicCountriesInput
            value={startFeedCard.getCountries()}
            countries={countries.map(c => ({ label: c.getIsoCode(), value: c.getIsoCode()}))}
            tagColor={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500}
            onInput={countryIsoCodes => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                clonedStartFeedCard.updateCountries(countryIsoCodes);
                return clonedStartFeedCard;
              });
            }}
          />
        </div>
      </PicField>
      <PicField label='Target Vip/Free Users'>
        <PicDropdown
          itemGroups={targetFreeVipUsersItemGroups}
          value={targetFreeVipUsersValue}
          itemLabelGetter={item => item.label}
          itemValueGetter={item => item.value}
          onSelect={item => {
            cloneStartFeedCardToUpdate(clonedStartFeedCard => {
              if (item.value === 'all') {
                clonedStartFeedCard.updateIsTargetVipUser(true);
                clonedStartFeedCard.updateIsTargetFreeUser(true);
              } else if (item.value === 'free_only') {
                clonedStartFeedCard.updateIsTargetVipUser(false);
                clonedStartFeedCard.updateIsTargetFreeUser(true);
              } else if (item.value === 'vip_only') {
                clonedStartFeedCard.updateIsTargetVipUser(true);
                clonedStartFeedCard.updateIsTargetFreeUser(false);
              } else {
                clonedStartFeedCard.updateIsTargetVipUser(false);
                clonedStartFeedCard.updateIsTargetFreeUser(false);
              }
              return clonedStartFeedCard;
            });
          }}
        />
      </PicField>
      <PicField label='Target New/Old Users' description='Based on the time when users first download the app to distinguish between new and old users.'>
        <PicDropdown
          itemGroups={targetNewOldUsersItemGroups}
          value={targetNewOldUsersValue}
          itemLabelGetter={item => item.label}
          itemValueGetter={item => item.value}
          onSelect={item => {
            cloneStartFeedCardToUpdate(clonedStartFeedCard => {
              if (item.value === 'all') {
                clonedStartFeedCard.updateIsTargetNewUser(true);
                clonedStartFeedCard.updateIsTargetOldUser(true);
              } else if (item.value === 'old_only') {
                clonedStartFeedCard.updateIsTargetNewUser(false);
                clonedStartFeedCard.updateIsTargetOldUser(true);
              } else if (item.value === 'new_only') {
                clonedStartFeedCard.updateIsTargetNewUser(true);
                clonedStartFeedCard.updateIsTargetOldUser(false);
              } else {
                clonedStartFeedCard.updateIsTargetNewUser(false);
                clonedStartFeedCard.updateIsTargetOldUser(false);
              }
              return clonedStartFeedCard;
            });
          }}
        />
      </PicField>
      <PicField
        label='Minimal Required Version for iOS'
        description={`On iOS, only users with app version ${startFeedCard.getTargetMinimalRequiredIosAppVersion().toString()} or above can see this start feed card`}
      >
        <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          <PicLink
            copy='Support All Versions'
            onClick={() => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                clonedStartFeedCard.updateTargetMinimalRequiredIosAppVersion(AppVersionVo.create(0, 0, 0));
                return clonedStartFeedCard;
              });
            }}
          />
          <PicAppVersionInput
            value={startFeedCard.getTargetMinimalRequiredIosAppVersion().toNumbers()}
            onInput={([major, minor, patch]) => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                const version = clonedStartFeedCard.getTargetMinimalRequiredIosAppVersion();
                clonedStartFeedCard.updateTargetMinimalRequiredIosAppVersion(
                  version.updateMajor(major).updateMinor(minor).updatePatch(patch),
                );
                return clonedStartFeedCard;
              });
            }}
          />
        </div>
      </PicField>
      <PicField
        label='Minimal Required Version for Android'
        description={`On Android, only users with app version ${startFeedCard.getTargetMinimalRequiredAndroidAppVersion().toString()} or above can see this start feed card`}
      >
        <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
          <PicLink
            copy='Support All Versions'
            onClick={() => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                clonedStartFeedCard.updateTargetMinimalRequiredAndroidAppVersion(AppVersionVo.create(0, 0, 0));
                return clonedStartFeedCard;
              });
            }}
          />
          <PicAppVersionInput
            value={startFeedCard.getTargetMinimalRequiredAndroidAppVersion().toNumbers()}
            onInput={([major, minor, patch]) => {
              cloneStartFeedCardToUpdate(clonedStartFeedCard => {
                const version = clonedStartFeedCard.getTargetMinimalRequiredAndroidAppVersion();
                clonedStartFeedCard.updateTargetMinimalRequiredAndroidAppVersion(
                  version.updateMajor(major).updateMinor(minor).updatePatch(patch),
                );
                return clonedStartFeedCard;
              });
            }}
          />
        </div>
      </PicField>
    </ListDataPagePanel>
  );
};
