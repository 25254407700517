import { TemplateCategoryStartFeedCardChipModel } from "./template-category-start-feed-card-chip-model";

export class NewTemplateCategoryStartFeedCardChipModel extends TemplateCategoryStartFeedCardChipModel {
  static create(name: string, templateTagId: number | null): NewTemplateCategoryStartFeedCardChipModel {
    return new NewTemplateCategoryStartFeedCardChipModel(name, templateTagId);
  }

  public clone(): NewTemplateCategoryStartFeedCardChipModel {
    return new NewTemplateCategoryStartFeedCardChipModel(this.name, this.templateTagId);
  }

  public isComplete(isNameRequired: boolean) {
    if (!this.templateTagId) return false;
    return isNameRequired ? !!this.name : true;
  }
}
