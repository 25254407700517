import * as React from 'react';
import { PicColor, PicCountryTag, PicField, PicFontSize, PicFontWeight, PicText, PicToggle} from '@cardinalblue/pic-collage-cms-ui';
import { PersistedStartFeedCardModel } from '../models/persisted-start-feed-card-model';
import { ListDataPagePanel } from '../../common/components/panels/list-data-page-panel';
import { isPersistedStartFeedCardModel } from '../models/utils';

type Props = {
  startFeedCard: PersistedStartFeedCardModel;
};

export function ShowStartFeedCardPanel({ startFeedCard }: Props) {
  const countries = startFeedCard.getCountries();
  const isCountriesExclusive = startFeedCard.getIsCountriesExclusive();

  return (
    <ListDataPagePanel title='Basic'>
      {isPersistedStartFeedCardModel(startFeedCard) && (
        <PicField label='Id'>
          <PicText>{startFeedCard.getId()}</PicText>
        </PicField>
      )}
      <PicField
        label='Is Pinned?'
      >
        <PicToggle value={startFeedCard.getIsPinned()} disabled />
      </PicField>
      <PicField
        label='Analytic Id'
      >
        <PicText>{startFeedCard.getAnalyticId()}</PicText>
      </PicField>
      <PicField
        label='Title'
      >
        <PicText>{startFeedCard.getTitle()}</PicText>
      </PicField>
      <PicField
        label='Subtitle'
      >
        <PicText>{startFeedCard.getSubtitle() || 'None'}</PicText>
      </PicField>
      <PicField
        label='Label'
      >
        <PicText>{startFeedCard.getLabel() || 'None'}</PicText>
      </PicField>
      <PicField
        label='Start At'
      >
        <PicText>{startFeedCard.getStartAt()?.toDateString(-8) || 'None'}</PicText>
      </PicField>
      <PicField
        label='End At'
      >
        <PicText>{startFeedCard.getEndAt()?.toDateString(-8) || 'None'}</PicText>
      </PicField>
      <PicField
        label='Target Countries'
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px', flexWrap: 'wrap' }}>
          <PicText size={PicFontSize.Sm} weight={PicFontWeight.Medium} color={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500}>{isCountriesExclusive ? 'Excludes' : 'Includes'}</PicText>
          {countries.map(c => (
            <PicCountryTag
              key={c}
              isoCode={c}
              color={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500}
            />
          ))}
          {countries.length === 0 && <PicText size={PicFontSize.Sm} weight={PicFontWeight.Medium} color={isCountriesExclusive ? PicColor.Red500 : PicColor.Teal500}>No Countries</PicText>}
        </div>
      </PicField>
      <PicField
        label='Target Free/Vip Users?'
      >
        <PicText>{startFeedCard.getTargetVipFreeUserCopy()}</PicText>
      </PicField>
      <PicField
        label='Target New/Old Users?'
      >
        <PicText>{startFeedCard.getTargetNewOldUserCopy()}</PicText>
      </PicField>
      <PicField
        label='Minimal Required Ios App Version'
      >
        <PicText>{startFeedCard.getTargetMinimalRequiredIosAppVersion().toString()}</PicText>
      </PicField>
      <PicField
        label='Minimal Required Android App Version'
      >
        <PicText>{startFeedCard.getTargetMinimalRequiredAndroidAppVersion().toString()}</PicText>
      </PicField>
    </ListDataPagePanel>
  );
};
