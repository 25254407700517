import { AppVersionVo } from "../../../common/models/app-version-vo";
import { INewStartFeedCardModel } from "../interfaces";
import { BaseStartFeedCardParams } from "../start-feed-card-model";
import { StartFeedCardTypeEnum } from "../start-feed-card-type-enum";
import { NewTemplateCategoryStartFeedCardChipModel } from "./template-category-start-feed-card-chips/new-template-category-start-feed-card-chip-model";
import { TemplateCategoryStartFeedCardModel } from "./template-category-start-feed-card-model";

export class NewTemplateCategoryStartFeedCardModel extends TemplateCategoryStartFeedCardModel implements INewStartFeedCardModel  {
  constructor(
    params: BaseStartFeedCardParams,
    protected chips: NewTemplateCategoryStartFeedCardChipModel[],
  ) {
    super(params);
  }

  static create(
    params: BaseStartFeedCardParams,
    chips: NewTemplateCategoryStartFeedCardChipModel[],
  ): NewTemplateCategoryStartFeedCardModel {
    return new NewTemplateCategoryStartFeedCardModel(
      params,
      chips,
    );
  }

  static createNew(): NewTemplateCategoryStartFeedCardModel {
    return new NewTemplateCategoryStartFeedCardModel(
      {
        analyticId: '',
        title: '',
        subtitle: '',
        label: '',
        startAt: null,
        endAt: null,
        isPinned: false,
        isCountriesExclusive: false,
        countries: [],
        isTargetFreeUser: true,
        isTargetVipUser: true,
        isTargetNewUser: true,
        isTargetOldUser: true,
        targetMinimalRequiredIosAppVersion: AppVersionVo.create(0, 0, 0),
        targetMinimalRequiredAndroidAppVersion: AppVersionVo.create(0, 0, 0),
        payloadType: StartFeedCardTypeEnum.TemplateCategory,
      },
      [NewTemplateCategoryStartFeedCardChipModel.create('', null)],
    );
  }

  public clone(): NewTemplateCategoryStartFeedCardModel {
    return new NewTemplateCategoryStartFeedCardModel(
      this.generateBaseStartFeedCardParams(),
      this.chips.map(c => c.clone()),
    );
  }

  public isComplete(): boolean {
    if (!this.analyticId || !this.title) return false;
    if (this.chips.length === 0) return false;
    return this.chips.every(chip => chip.isComplete(this.chips.length > 1));
  }

  public getChips(): NewTemplateCategoryStartFeedCardChipModel[] {
    return this.chips
  }

  public addChip(chip: NewTemplateCategoryStartFeedCardChipModel): void {
    this.chips.push(chip);
  }

  public removeChip(chipIndex: number): void {
    this.chips = this.chips.filter((_, index) => index !== chipIndex);
  }

  public updateChip(chipIndex: number, newChip: NewTemplateCategoryStartFeedCardChipModel): void {
    this.chips = this.chips.map((chip, index) => index !== chipIndex ? chip : newChip);
  }

  public updateChips(chips: NewTemplateCategoryStartFeedCardChipModel[]): void {
    this.chips = chips
  }
}
