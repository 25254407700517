import { AppVersionVo } from "../../../common/models/app-version-vo";
import { DateVo } from "../../../common/models/date-vo";
import { generateObjectUrlFromUnit8Array } from "../../../common/utils/file";
import { IPersistedStartFeedCardModel } from "../interfaces";
import { BaseStartFeedCardParams } from "../start-feed-card-model";
import { StartFeedCardTypeEnum } from "../start-feed-card-type-enum";
import { LinkStartFeedCardModel } from "./link-start-feed-card-model";
import { NewLinkStartFeedCardModel } from "./new-link-start-feed-card-model";

export class PersistedLinkStartFeedCardModel extends LinkStartFeedCardModel implements IPersistedStartFeedCardModel {
  constructor(
    params: BaseStartFeedCardParams,
    protected id: number,
    protected paylodId: number,
    link: string,
    image: Uint8Array | null,
    protected imageUrl: string,
    protected imageSignedId: string,
  ) {
    super(
      params,
      link,
      image,
    );
  }

  static create(
    id: number,
    analyticId: string,
    title: string,
    subtitle: string,
    label: string,
    startAt: DateVo | null,
    endAt: DateVo | null,
    isPinned: boolean,
    isCountriesExclusive: boolean,
    countries: string[],
    isTargetFreeUser: boolean,
    isTargetVipUser: boolean,
    isTargetNewUser: boolean,
    isTargetOldUser: boolean,
    targetMinimalRequiredIosAppVersion: AppVersionVo,
    targetMinimalRequiredAndroidAppVersion: AppVersionVo,
    payloadType: StartFeedCardTypeEnum,
    paylodId: number,
    link: string,
    imageUrl: string,
    imageSignedId: string,
  ) {
    return new PersistedLinkStartFeedCardModel(
      { analyticId, title, subtitle, label, startAt, endAt, isPinned, isCountriesExclusive, countries, isTargetFreeUser, isTargetVipUser, isTargetNewUser, isTargetOldUser, targetMinimalRequiredIosAppVersion, targetMinimalRequiredAndroidAppVersion, payloadType },
      id,
      paylodId,
      link,
      null,
      imageUrl,
      imageSignedId,
    );
  }

  public clone(): PersistedLinkStartFeedCardModel {
    return new PersistedLinkStartFeedCardModel(
      this.generateBaseStartFeedCardParams(),
      this.id,
      this.paylodId,
      this.link,
      this.image,
      this.imageUrl,
      this.imageSignedId,
    );
  }

  public toNewStartFeedCard(): NewLinkStartFeedCardModel {
    const newModel = NewLinkStartFeedCardModel.create(
      this.generateBaseStartFeedCardParams(),
      this.link,
      null,
      this.imageUrl,
      this.imageSignedId,
    );
    newModel.updateAnalyticId('');
    return newModel;
  }

  public isComplete(): boolean {
    return true
  }

  public getId(): number {
    return this.id;
  }

  public getPayloadId(): number {
    return this.paylodId;
  }

  public getImageUrl() {
    const image = this.getImage();
    if (!image) return this.imageUrl;

    return generateObjectUrlFromUnit8Array(image);
  }

  public getImageSignedId(): string {
    return this.imageSignedId;
  }
}
