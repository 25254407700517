import * as React from 'react';
import { PicField, PicImage, PicText } from '@cardinalblue/pic-collage-cms-ui';
import { PersistedLinkStartFeedCardModel } from '../models/link-start-feed-cards/persisted-link-start-feed-card-model';
import { ListDataPagePanel } from '../../common/components/panels/list-data-page-panel';


type Props = {
  linkStartFeedCard: PersistedLinkStartFeedCardModel;
};

export function ShowLinkStartFeedCardPanel({ linkStartFeedCard }: Props) {
  const imageUrl = React.useMemo(() => linkStartFeedCard.getImageUrl(), [linkStartFeedCard]);

  return (
    <ListDataPagePanel title='Link'>
      <PicField label='Link'>
        <PicText>{linkStartFeedCard.getLink()}</PicText>
      </PicField>
      <PicField label='Image'>
        <PicImage
          src={imageUrl}
          width='200px'
        />
      </PicField>
    </ListDataPagePanel>
  );
};
