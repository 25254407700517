import { IStartFeedCardModel } from "../interfaces";
import { StartFeedCardModel } from "../start-feed-card-model";
import { TemplateCategoryStartFeedCardChipModel } from "./template-category-start-feed-card-chips/template-category-start-feed-card-chip-model";

export abstract class TemplateCategoryStartFeedCardModel extends StartFeedCardModel implements IStartFeedCardModel {
  abstract clone(): TemplateCategoryStartFeedCardModel;

  abstract getChips(): TemplateCategoryStartFeedCardChipModel[];

  abstract addChip(chip: TemplateCategoryStartFeedCardChipModel): void;

  abstract removeChip(chipIndex: number): void;

  abstract updateChip(chipIndex: number, chip: TemplateCategoryStartFeedCardChipModel): void;

  abstract updateChips(chips: TemplateCategoryStartFeedCardChipModel[]): void;
}
