import { TemplateTagModel } from '../../common/models/template-tag-model';
import { TemplateDto, parseTemplateDto } from '../../common/dtos/template-dto';
import { TemplateTagDto, parseTemplateTagDto } from '../../common/dtos/template-tag-dto';
import { TemplateModel } from '../../common/models/template-model';

type BatchTaggingTemplateRequestBody = {
  template_id: number;
  template_tag_ids: number[];
}[];

export class TemplateTagApi {
  static create() {
    return new TemplateTagApi();
  }

  async fetchTemplateTag(templateTagId: number): Promise<[error: Error | null, templateTag: TemplateTagModel]> {
    const response = await fetch(`/cms_api/template_tags/${templateTagId}`);

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return [new Error(error), TemplateTagModel.createMock()];
    } else {
      const templateTag = (await response.json()) as TemplateTagDto;
      return [null, parseTemplateTagDto(templateTag)];
    }
  }

  async patchTemplateTag(formAuthenticityToken: string, templateTag: TemplateTagModel): Promise<Error | null> {
    const formData = new FormData();
    formData.append('_method', 'patch');
    formData.append('authenticity_token', formAuthenticityToken);
    formData.append('template_tag[name]', templateTag.getName());
    formData.append('template_tag[randomizable]', templateTag.getRandomizable() ? '1' : '0');
    formData.append('template_tag[is_niche]', templateTag.getIsNiche() ? '1' : '0');

    const response = await fetch(`/template_tags/${templateTag.getId()}`, {
      method: 'PATCH',
      body: formData,
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }

  async updateTemplateOrder(
    formAuthenticityToken: string,
    templateTag: TemplateTagModel,
    templates: TemplateModel[] | null,
  ): Promise<Error | null> {
    const formData = new FormData();

    formData.append('_method', 'patch');
    formData.append('authenticity_token', formAuthenticityToken);

    // This template_tag field is just to satisfy this endpoint's requirement,
    // ideally you shouldn't pass in an updated template tag.
    formData.append('template_tag[name]', templateTag.getName());
    formData.append('template_tag[randomizable]', templateTag.getRandomizable() ? '1' : '0');
    formData.append('template_tag[is_niche]', templateTag.getIsNiche() ? '1' : '0');

    formData.append('commit', 'Save');

    if (templates) {
      const activeTemplateIds = templates
        .filter((template) => template.getIsActive())
        .map((template) => template.getId());
      const inactiveTemplateIds = templates
        .filter((template) => !template.getIsActive())
        .map((template) => template.getId());
      formData.append('active_template_ids', activeTemplateIds.join(','));
      formData.append('inactive_template_ids', inactiveTemplateIds.join(','));
    }

    const response = await fetch(`/template_tags/${templateTag.getId()}`, {
      method: 'PATCH',
      body: formData,
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }

  async fetchTemplatesByTag(templateTagId: number): Promise<[error: Error | null, templateTag: TemplateModel[]]> {
    const response = await fetch(`/cms_api/template_tags/${templateTagId}/templates`);

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return [new Error(error), []];
    } else {
      const templateDtos = (await response.json()) as TemplateDto[];
      return [null, templateDtos.map(parseTemplateDto)];
    }
  }

  async batchTagTemplates(templates: TemplateModel[]): Promise<Error | null> {
    const batchTagTemplates: BatchTaggingTemplateRequestBody = templates.map((template) => ({
      template_id: template.getId(),
      template_tag_ids: template.getTemplateTagIds(),
    }));
    const response = await fetch(`/cms_api/templates/batch_tagging`, {
      method: 'PUT',
      body: JSON.stringify(batchTagTemplates),
    });

    if (!response.ok) {
      const { error } = (await response.json()) as { error: string };
      return new Error(error);
    } else {
      return null;
    }
  }
}
