export abstract class TemplateCategoryStartFeedCardChipModel {
  constructor(
    protected name: string,
    protected templateTagId: number | null,
  ) {}

  abstract clone(): TemplateCategoryStartFeedCardChipModel;

  abstract isComplete(isNameRequired: boolean): boolean;

  public getName(): string {
    return this.name
  }

  public updateName(name: string): void {
    this.name = name;
  }

  public getTemplateTagId(): number | null {
    return this.templateTagId
  }

  public updateTemplateTagId(templateTagId: number | null): void {
    this.templateTagId = templateTagId;
  }
}
