import * as React from 'react';
import {
  PicNotifier,
  PicColor,
  PicTag,
  PicIconName,
  PicButton,
  PicField,
  PicConfirmer,
  PicLink,
  usePicBulkEditItems,
  PicReorderItemsPanel,
  PicToggle,
  PicText,
  PicInput,
  PicRouter,
  PicFontSize,
  PicFontWeight,
  PicContainer,
  PicGrid,
} from '@cardinalblue/pic-collage-cms-ui';
import { CommonContextProvider } from '../features/common/contexts/common-context';
import {
  convertArrayToMap,
  deduplicateArray,
  getAddedItemsInArray,
  getRemovedItemsInArray,
} from '../features/common/utils/array';
import { TemplateModel } from '../features/common/models/template-model';
import { TemplateTagModel } from '../features/common/models/template-tag-model';
import { TemplateTagSelect } from '../features/common/components/selects/template-tags-select';
import { TemplateTagApi } from '../features/template-tags/apis/template-tag-api';
import { TemplateCard } from '../features/template-tags/components/template-card';
import { TemplateTagDto, parseTemplateTagDto } from '../features/common/dtos/template-tag-dto';
import { CheckAddedRemovedTemplateTagsModal } from '../features/template-tags/components/check-added-removed-template-tags-modal';
import { ReorganizeDataController } from '../features/common/components/controllers/reorganize-data-controller';
import { ContentRenderer } from '../features/common/components/renderers/content-renderer';
import { ListDataPagePanel } from '../features/common/components/panels/list-data-page-panel';
import { TemplateFilterOrderController } from '../features/template-tags/components/template-filter-order-controller';
import { TemplateFilterSettingsVo } from '../features/template-tags/models/template-filter-settings-vo';
import {
  filterTemplateWithTemplateFilterSettings,
  orderTemplatesWithTemplateFilterSettings,
} from '../features/template-tags/utils/template-filter';
import { TemplateOrderEnum } from '../features/template-tags/models/template-order-enum';

type Props = {
  formAuthenticityToken: string;
  templateTagId: number;
  templateTagDtos: TemplateTagDto[];
};

const EditTemplateTagPageContent: React.FC<Props> = ({ formAuthenticityToken, templateTagId, templateTagDtos }) => {
  const picNotifier = React.useMemo(() => new PicNotifier(), []);
  const picConfirmer = React.useMemo(() => new PicConfirmer(), []);
  const picRouter = React.useMemo(() => new PicRouter(), []);
  const templateTagApi = React.useMemo(() => TemplateTagApi.create(), []);
  const templateTags = React.useMemo(() => templateTagDtos.map((dto) => parseTemplateTagDto(dto)), [templateTagDtos]);
  const templateTagMap = React.useMemo(
    () => convertArrayToMap(templateTags, (templateTag) => templateTag.getId()),
    [templateTags],
  );

  const [templateTag, setTemplateTag] = React.useState<TemplateTagModel | null>(null);
  const isFetchingTemplateTag = React.useMemo(() => templateTag === null, [templateTag]);
  const [draftTemplateTag, setDraftTemplateTag] = React.useState<TemplateTagModel | null>(null);
  const [templateTagEditMode, setTemplateTagEditMode] = React.useState<'edit' | 'read'>('read');

  const handleTemplateTagNameChange = React.useCallback((newName: string) => {
    setDraftTemplateTag((prevTag) => {
      if (!prevTag) return null;
      const updatedTag = prevTag.clone();
      updatedTag.updateName(newName);
      return updatedTag;
    });
  }, []);

  const handleTemplateTagRandomizableChange = React.useCallback((newRandomizable: boolean) => {
    setDraftTemplateTag((prevTag) => {
      if (!prevTag) return null;
      const updatedTag = prevTag.clone();
      updatedTag.updateRandomizable(newRandomizable);
      return updatedTag;
    });
  }, []);

  const handleTemplateTagIsNicheChange = React.useCallback((isNiche: boolean) => {
    setDraftTemplateTag((prevTag) => {
      if (!prevTag) return null;
      const updatedTag = prevTag.clone();
      updatedTag.updateIsNiche(isNiche);
      return updatedTag;
    });
  }, []);

  const resetDraftTemplateTag = React.useCallback(() => {
    setDraftTemplateTag(templateTag ? templateTag.clone() : null);
  }, [templateTag]);

  React.useEffect(() => {
    resetDraftTemplateTag();
  }, [resetDraftTemplateTag]);

  const fetchTemplateTag = React.useCallback(async (): Promise<Error | null> => {
    const [error, returnedTemplateTag] = await templateTagApi.fetchTemplateTag(templateTagId);
    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return error;
    }
    setTemplateTag(returnedTemplateTag);
    return null;
  }, [templateTagApi, picNotifier]);

  React.useEffect(() => {
    fetchTemplateTag();
  }, [fetchTemplateTag]);

  const handleTemplateTagSaveClick = React.useCallback(
    async (close: () => void) => {
      if (!draftTemplateTag) {
        close();
        return;
      }

      let error = await templateTagApi.patchTemplateTag(formAuthenticityToken, draftTemplateTag);
      if (error) {
        close();
        return;
      }

      error = await fetchTemplateTag();
      if (error) {
        close();
        return;
      }

      setTemplateTagEditMode('read');
      picNotifier.notify({ type: 'success', message: 'Successfully updated template tag' });
      close();
    },
    [formAuthenticityToken, draftTemplateTag, templateTagApi, picNotifier, fetchTemplateTag],
  );

  const templateKeyGetter = React.useCallback((template: TemplateModel) => template.getId(), []);
  const templateCloner = React.useCallback((template: TemplateModel) => template.clone(), []);

  const [templates, setTemplates] = React.useState<TemplateModel[]>([]);
  const templateMap = React.useMemo(() => convertArrayToMap(templates, (template) => template.getId()), [templates]);

  const [isFetchingTemplates, setIsFetchingTemplates] = React.useState(false);

  const [templateFilterSettings, setTemplateFilterSettings] = React.useState<TemplateFilterSettingsVo>(
    TemplateFilterSettingsVo.createDefault(),
  );

  const [templateOrder, setTemplateOrder] = React.useState(TemplateOrderEnum.Default);

  const templateFilter = React.useCallback(
    (template: TemplateModel) => {
      return filterTemplateWithTemplateFilterSettings(template, templateFilterSettings);
    },
    [templateFilterSettings],
  );

  const orderTemplates = React.useCallback(
    (templates: TemplateModel[]) => {
      return orderTemplatesWithTemplateFilterSettings(templates, templateOrder);
    },
    [templateOrder],
  );

  const {
    draftItems: draftTemplates,
    filteredDraftItems: filteredDraftTemplates,
    selectedDraftItems: selectedDraftTemplates,
    selectedDraftItemKeys: selectedDraftTemplateKeys,
    canUndoDraftItemsChange: canUndoDraftTemplatesChange,
    canRedoDraftItemsChange: canRedoDraftTemplatesChange,
    selectDraftItems: selectDraftTemplates,
    moveSelectedDraftItemsToTop: moveSelectedDraftTemplatesToTop,
    moveSelectedDraftItemsToBottom: moveSelectedDraftTemplatesToBottom,
    selectAllDraftItems: selectAllDraftTemplates,
    undoDraftItemsChange: undoDraftTemplatesChange,
    redoDraftItemsChange: redoDraftTemplatesChange,
    deselectAllSelectedDraftItems: deselectAllSelectedDraftTemplates,
    reorderDraftItems: reorderDraftTemplates,
    updateAllDraftItems: updateAllDraftTemplates,
    updateSelectedDraftItems: updateSelectedDraftTemplates,
    clearAllDraftItemsChanges: clearDraftTemplatesChanges,
  } = usePicBulkEditItems({
    items: templates,
    itemCloner: templateCloner,
    itemKeyGetter: templateKeyGetter,
    itemFilter: templateFilter,
  });

  const activeTemplateCount: number = React.useMemo(
    () => templates.filter((template) => template.getIsActive()).length,
    [templates],
  );
  const hiddenTemplateCount: number = React.useMemo(
    () => templates.filter((template) => !template.getIsActive()).length,
    [templates],
  );

  const orderedFilteredDraftItems = React.useMemo(
    () => orderTemplates(filteredDraftTemplates),
    [filteredDraftTemplates, orderTemplates],
  );

  const maxTemplateSlotCount = React.useMemo(() => {
    return draftTemplates.reduce((max, template) => Math.max(max, template.getSlotsCount()), 0);
  }, [draftTemplates]);

  const [isBulkTagTemplatesPanelVisible, setIsBulkTagTemplatesPanelVisible] = React.useState(true);

  const [isTemplateBulkTaggingLogsModalVisible, setIsTemplateBulkTaggingLogsModalVisible] = React.useState(false);

  const [templateReorganizeMode, setTemplateReorganizeMode] = React.useState<'edit' | 'read'>('read');

  const extractUniqueTags = React.useCallback(
    (selectedDraftTemplates: TemplateModel[]) => {
      const templateTagIds = selectedDraftTemplates.flatMap((template) => template.getTemplateTagIds());
      const uniqueTagIds = deduplicateArray(templateTagIds, (tagId) => tagId.toString());
      return templateTags.reduce((acc: TemplateTagModel[], tag) => {
        if (uniqueTagIds.includes(tag.getId())) {
          acc.push(tag);
        }
        return acc;
      }, []);
    },
    [templateTags],
  );

  const handleTemplateTagIdsInput = React.useCallback(
    (newTemplateTag: TemplateTagModel) => {
      updateSelectedDraftTemplates((prevSelectedDraftTemplates) => {
        prevSelectedDraftTemplates.forEach((draftTemplate) => {
          draftTemplate.addTemplateTagIds([newTemplateTag.getId()]);
        });
        return prevSelectedDraftTemplates;
      });
    },
    [updateSelectedDraftTemplates],
  );

  const fetchTemplatesByTag = React.useCallback(async (): Promise<Error | null> => {
    setTemplates([]);

    setIsFetchingTemplates(true);
    const [error, returnedTemplates] = await templateTagApi.fetchTemplatesByTag(templateTagId);
    setIsFetchingTemplates(false);

    if (error) {
      picNotifier.notify({
        type: 'error',
        message: error.message,
      });
      return error;
    }
    setTemplates(returnedTemplates);
    return null;
  }, [templateTagApi, picNotifier]);

  React.useEffect(() => {
    fetchTemplatesByTag();
  }, [fetchTemplatesByTag]);

  const getDraftTemplateAddedAndRemovedTags = React.useCallback(
    (template: TemplateModel, draftTemplate: TemplateModel) => {
      const addedTemplateTagIds = getAddedItemsInArray(
        template.getTemplateTagIds(),
        draftTemplate.getTemplateTagIds(),
        (id) => id,
      );
      const removedTemplateTagIds = getRemovedItemsInArray(
        template.getTemplateTagIds(),
        draftTemplate.getTemplateTagIds(),
        (id) => id,
      );
      return { addedTemplateTagIds, removedTemplateTagIds };
    },
    [],
  );

  const saveTemplatesBulkTagging = React.useCallback(async (): Promise<Error | null> => {
    const draftTemplateWithChanges = draftTemplates.filter((draftTemplate) => {
      const template = templateMap[draftTemplate.getId()];
      const { addedTemplateTagIds, removedTemplateTagIds } = getDraftTemplateAddedAndRemovedTags(
        template,
        draftTemplate,
      );
      return addedTemplateTagIds.length > 0 || removedTemplateTagIds.length > 0;
    });

    return await templateTagApi.batchTagTemplates(draftTemplateWithChanges);
  }, [draftTemplates, templateMap, getDraftTemplateAddedAndRemovedTags, templateTagApi]);

  const saveTemplateOrder = React.useCallback(async (): Promise<Error | null> => {
    if (!templateTag) return new Error('No template tag');

    const hasTemplateOrderChanged = draftTemplates.some(
      (draftTemplate, index) => draftTemplate.getId() !== templates[index].getId(),
    );
    // If the order of the templates has not changed, we can skip the update if only the active status has not changed
    if (!hasTemplateOrderChanged) {
      const hasActiveChanged = draftTemplates.some(
        (draftTemplate, index) => draftTemplate.getIsActive() !== templates[index].getIsActive(),
      );
      if (!hasActiveChanged) {
        return null;
      }
    }

    return await templateTagApi.updateTemplateOrder(formAuthenticityToken, templateTag, draftTemplates);
  }, [formAuthenticityToken, draftTemplates, templateTag, templateTagApi]);

  const handleTemplatesReorganizeSave = React.useCallback(
    async (done: () => void) => {
      let error = await saveTemplatesBulkTagging();
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
      }

      error = await saveTemplateOrder();
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
      }

      picNotifier.notify({
        type: 'success',
        message: 'Template changes saved!',
      });

      setTemplateReorganizeMode('read');
      deselectAllSelectedDraftTemplates();
      done();

      error = await fetchTemplatesByTag();
      if (error) {
        picNotifier.notify({
          type: 'error',
          message: error.message,
        });
      }
    },
    [saveTemplatesBulkTagging, saveTemplateOrder, picNotifier, fetchTemplatesByTag],
  );

  const handleRemoveTagClick = React.useCallback(
    async (tagId: number) => {
      if (tagId === templateTagId) {
        const templateTag = templateTagMap[tagId];
        const [confirmed, close] = await picConfirmer.pop({
          title: 'Caution',
          message: `If you remove "${templateTag.getName()}" tag from selected templates, those templates will be removed from this page.`,
        });
        close();
        if (!confirmed) {
          return;
        }
      }

      updateSelectedDraftTemplates((prevSelectedDraftTemplates) => {
        prevSelectedDraftTemplates.forEach((draftTemplate) => {
          draftTemplate.removeTemplateTagIds([tagId]);
        });
        return prevSelectedDraftTemplates;
      });
    },
    [updateSelectedDraftTemplates],
  );

  const uniqueTagsInSelectedDraftTemplates = React.useMemo(
    () => extractUniqueTags(selectedDraftTemplates),
    [selectedDraftTemplates],
  );

  const handleTemplatesActivinessToggle = React.useCallback(() => {
    updateSelectedDraftTemplates((previousSelectedDraftTemplates) => {
      const hasAnyActiveTemplates = previousSelectedDraftTemplates.some((template) => template.getIsActive());

      previousSelectedDraftTemplates.forEach((template) => {
        template.updateIsActive(!hasAnyActiveTemplates);
      });

      return previousSelectedDraftTemplates;
    });
  }, [updateSelectedDraftTemplates]);

  const goToEditTemplatePage = React.useCallback(
    (template: TemplateModel) => {
      return () => {
        picRouter.redirect(`/templates/${template.getId()}/edit`);
      };
    },
    [picRouter],
  );

  const handleRevertAddTemplateTags = React.useCallback(
    (templateTagId: number, templateIds: number[]) => {
      updateAllDraftTemplates((previousDraftTemplates) => {
        previousDraftTemplates.forEach((previousDraftTemplate) => {
          console.log(previousDraftTemplate, templateIds);
          if (templateIds.includes(previousDraftTemplate.getId())) {
            previousDraftTemplate.removeTemplateTagIds([templateTagId]);
          }
        });
        return previousDraftTemplates;
      });
    },
    [updateAllDraftTemplates],
  );

  const handleRevertRemoveTemplateTags = React.useCallback(
    (templateTagId: number, templateIds: number[]) => {
      updateAllDraftTemplates((previousDraftTemplates) => {
        previousDraftTemplates.forEach((previousDraftTemplate) => {
          if (templateIds.includes(previousDraftTemplate.getId())) {
            previousDraftTemplate.addTemplateTagIds([templateTagId]);
          }
        });
        return previousDraftTemplates;
      });
    },
    [updateAllDraftTemplates],
  );

  return (
    <div>
      <CheckAddedRemovedTemplateTagsModal
        opened={isTemplateBulkTaggingLogsModalVisible}
        templateTags={templateTags}
        sourceTemplates={templates}
        draftTemplates={draftTemplates}
        onRevertAddTemplateTags={handleRevertAddTemplateTags}
        onRevertRemoveTemplateTags={handleRevertRemoveTemplateTags}
        onClose={() => {
          setIsTemplateBulkTaggingLogsModalVisible(false);
        }}
      />
      <div style={{ display: 'flex', flexFlow: 'column', gap: '40px' }}>
        <div style={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
          <PicLink
            copy="< Template Tags"
            onClick={() => {
              window.location.href = '/template_tags';
            }}
          />
          <PicLink
            copy="< Old Edit Template Tag"
            onClick={() => {
              window.location.href = `/template_tags/${templateTagId}/edit`;
            }}
          />
        </div>
        <div style={{ display: 'flex', flexFlow: 'column', gap: '20px' }}>
          <ReorganizeDataController
            mode={templateTagEditMode}
            itemName={{ singular: 'template category', plural: 'template categories' }}
            title="Template Category"
            confirmCancelTitle="Cancel Editing?"
            confirmCancelMessage="By cancelling, you will lose your changes to the template tag."
            confirmSaveTitle="Save Changes?"
            confirmSaveMessage="Are you sure you want to save the changes to the template tag?"
            reorganizeButtonCopy="Edit"
            onSaveConfirm={handleTemplateTagSaveClick}
            onCancelConfirm={() => {
              resetDraftTemplateTag();
              setTemplateTagEditMode('read');
            }}
            isEditButtonDisabled={templateReorganizeMode === 'edit'}
            onEditClick={() => {
              setTemplateTagEditMode('edit');
            }}
          />
          <ContentRenderer loading={isFetchingTemplateTag} noContent={false}>
            {draftTemplateTag && (
              <ListDataPagePanel column={3}>
                <PicField label="Name">
                  {templateTagEditMode === 'read' ? (
                    <PicText>{draftTemplateTag.getName()}</PicText>
                  ) : (
                    <PicInput value={draftTemplateTag.getName()} onInput={handleTemplateTagNameChange} />
                  )}
                </PicField>
                <PicField label="Randomize?">
                  {templateTagEditMode === 'read' ? (
                    <PicText>{draftTemplateTag.getRandomizable() ? 'Yes' : 'No'}</PicText>
                  ) : (
                    <PicToggle
                      value={draftTemplateTag.getRandomizable()}
                      onChange={handleTemplateTagRandomizableChange}
                    />
                  )}
                </PicField>
                <PicField label="Niche?">
                  {templateTagEditMode === 'read' ? (
                    <PicText>{draftTemplateTag.getIsNiche() ? 'Yes' : 'No'}</PicText>
                  ) : (
                    <PicToggle value={draftTemplateTag.getIsNiche()} onChange={handleTemplateTagIsNicheChange} />
                  )}
                </PicField>
              </ListDataPagePanel>
            )}
          </ContentRenderer>
        </div>
        <div style={{ marginTop: '20px', display: 'flex', flexFlow: 'column', gap: '20px' }}>
          <ReorganizeDataController
            title={
              <PicText size={PicFontSize.Lg} weight={PicFontWeight.Light}>
                <b style={{ color: PicColor.Teal500, fontWeight: PicFontWeight.Medium }}>
                  {activeTemplateCount} Active
                </b>
                {' and '}
                <b style={{ color: PicColor.Red500, fontWeight: PicFontWeight.Medium }}>{hiddenTemplateCount} Hidden</b>
                {' Templates'}
              </PicText>
            }
            mode={templateReorganizeMode}
            itemName={{ singular: 'template', plural: 'templates' }}
            isEditButtonDisabled={templateTagEditMode === 'edit'}
            onEditClick={() => {
              setTemplateReorganizeMode('edit');
            }}
            onCancelConfirm={() => {
              clearDraftTemplatesChanges();
              deselectAllSelectedDraftTemplates();
              setTemplateReorganizeMode('read');
            }}
            onSaveConfirm={handleTemplatesReorganizeSave}
            upperPanel={
              <TemplateFilterOrderController
                templateFilterSetting={templateFilterSettings}
                onTemplateFilterSettingUpdate={setTemplateFilterSettings}
                templateOrder={templateOrder}
                onTemplateOrderUpdate={setTemplateOrder}
                maxSlotCount={maxTemplateSlotCount}
              />
            }
            panel={
              <div style={{ display: 'flex', flexFlow: 'column', gap: '16px' }}>
                <div style={{ display: 'flex', flexFlow: 'column', gap: '8px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <PicLink
                      copy={isBulkTagTemplatesPanelVisible ? 'Hide Bulk Tag Panel' : 'Show Bulk Tag Panel'}
                      onClick={() => {
                        setIsBulkTagTemplatesPanelVisible(!isBulkTagTemplatesPanelVisible);
                      }}
                    />
                    <PicLink
                      copy="Check Added/Removed Tags"
                      onClick={() => {
                        setIsTemplateBulkTaggingLogsModalVisible(true);
                      }}
                    />
                  </div>
                  {isBulkTagTemplatesPanelVisible && (
                    <PicContainer background={PicColor.White}>
                      <PicGrid>
                        <PicField
                          label="Add Tags"
                          description="Tag will be directly added to selected templates after selection"
                        >
                          <TemplateTagSelect
                            templateTagId={null}
                            templateTags={templateTags}
                            onSelect={handleTemplateTagIdsInput}
                          />
                        </PicField>
                        <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                          <PicField
                            label="Remove Tags"
                            description={
                              uniqueTagsInSelectedDraftTemplates.length === 0
                                ? 'No associated tags from your selection.'
                                : 'Click cross icon beside each tag to remove it'
                            }
                          >
                            <div style={{ display: 'flex', gap: '4px', flexWrap: 'wrap' }}>
                              {uniqueTagsInSelectedDraftTemplates.map((tag) => (
                                <span key={tag.getId()}>
                                  <PicTag
                                    color={PicColor.Pink500}
                                    copy={tag.getName()}
                                    iconName={PicIconName.Close}
                                    onIconClick={() => handleRemoveTagClick(tag.getId())}
                                  />
                                </span>
                              ))}
                            </div>
                          </PicField>
                        </div>
                      </PicGrid>
                    </PicContainer>
                  )}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div>
                    {(!templateFilterSettings.isDefault() || templateOrder !== TemplateOrderEnum.Default) && (
                      <PicText color={PicColor.Yellow500} weight={PicFontWeight.Medium}>
                        You cannot drag templates when filter or order is applied
                      </PicText>
                    )}
                  </div>
                  <div style={{ display: 'flex', gap: '4px', justifyContent: 'flex-end' }}>
                    <PicButton
                      type="primary"
                      iconName={PicIconName.Undo}
                      disabled={!canUndoDraftTemplatesChange}
                      onClick={undoDraftTemplatesChange}
                    />
                    <PicButton
                      type="primary"
                      iconName={PicIconName.Redo}
                      disabled={!canRedoDraftTemplatesChange}
                      onClick={redoDraftTemplatesChange}
                    />
                    <PicButton type="primary" copy="Deselect" onClick={deselectAllSelectedDraftTemplates} />
                    <PicButton type="primary" copy="Select All" onClick={selectAllDraftTemplates} />
                    <PicButton type="primary" copy="Show/Hide" onClick={handleTemplatesActivinessToggle} />
                    <PicButton type="primary" iconName={PicIconName.Up} onClick={moveSelectedDraftTemplatesToTop} />
                    <PicButton
                      type="primary"
                      iconName={PicIconName.Down}
                      onClick={moveSelectedDraftTemplatesToBottom}
                    />
                  </div>
                </div>
              </div>
            }
          />
          <ContentRenderer
            loading={isFetchingTemplates}
            noContent={orderedFilteredDraftItems.length === 0}
            noContentCopy={
              templateFilterSettings.isDefault() ? undefined : 'No results found after applying the filter'
            }
          >
            <PicReorderItemsPanel
              column={10}
              items={orderedFilteredDraftItems}
              itemKeyGetter={templateKeyGetter}
              selectedItemKeys={selectedDraftTemplateKeys}
              onSelectedItemKeysChange={selectDraftTemplates}
              disableDragging={templateReorganizeMode === 'read' || !templateFilterSettings.isDefault()}
              disableSelection={templateReorganizeMode === 'read'}
              onItemsReorder={(draftTemplates) => {
                reorderDraftTemplates(draftTemplates.map(templateKeyGetter));
              }}
              itemRenderer={({ selected, item: draftTemplate, onSelect }) => {
                const template = templateMap[draftTemplate.getId()];
                const { addedTemplateTagIds, removedTemplateTagIds } = getDraftTemplateAddedAndRemovedTags(
                  template,
                  draftTemplate,
                );

                return (
                  <TemplateCard
                    template={draftTemplate}
                    selected={selected}
                    addedTemplateTagCount={addedTemplateTagIds.length}
                    removedTemplateTagCount={removedTemplateTagIds.length}
                    onClick={templateReorganizeMode === 'read' ? goToEditTemplatePage(draftTemplate) : onSelect}
                  />
                );
              }}
            />
          </ContentRenderer>
        </div>
      </div>
    </div>
  );
};

const EditTemplateTagPage: React.FC<Props> = (props) => {
  return (
    <CommonContextProvider>
      <EditTemplateTagPageContent {...props} />
    </CommonContextProvider>
  );
};

export default EditTemplateTagPage;
