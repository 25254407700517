import { AppVersionVo } from '../models/app-version-vo';
import { DateVo } from '../models/date-vo';
import { TemplateTagModel } from '../models/template-tag-model';

export type TemplateTagDto = {
  id: number;
  name: string;
  priority: number;
  randomizable: boolean;
  created_at: string;
  updated_at: string;
  is_niche: boolean;
  need_feature_compatibility_check: boolean;
  minimal_required_version_ios?: number[];
  minimal_required_version_android?: number[];
};

export const parseTemplateTagDto = (dto: TemplateTagDto): TemplateTagModel => {
  return TemplateTagModel.create(
    dto.id,
    dto.name,
    dto.priority,
    dto.randomizable,
    DateVo.parseTimeString(dto.created_at),
    DateVo.parseTimeString(dto.created_at),
    dto.is_niche,
    dto.need_feature_compatibility_check,
    dto.minimal_required_version_ios
      ? AppVersionVo.create(
          dto.minimal_required_version_ios[0] || 0,
          dto.minimal_required_version_ios[1] || 0,
          dto.minimal_required_version_ios[2] || 0,
        )
      : null,
    dto.minimal_required_version_android
      ? AppVersionVo.create(
          dto.minimal_required_version_android[0] || 0,
          dto.minimal_required_version_android[1] || 0,
          dto.minimal_required_version_android[2] || 0,
        )
      : null,
  );
};
